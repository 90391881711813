// Default behavior for the sidenav layout
// The default positioning for the sidenav is a static position

#layoutSidenav {
    display: flex;

    // Wraps the .sidenav element and sets the size
    #layoutSidenav_nav {
        flex-basis: $sidenav-base-width;
        flex-shrink: 0;
        transition: transform 0.15s ease-in-out;
        z-index: $zindex-sidenav;
        // Mobile first transform - by default the sidenav will be moved off-canvas
        transform: translateX(-$sidenav-base-width);
    }

    // Wraps the content when using the sidenav layout
    #layoutSidenav_content {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-width: 0;
        flex-grow: 1;
        min-height: calc(100vh - #{$topnav-base-height});
        margin-left: -$sidenav-base-width;
    }
}

// Default behavior for the static sidenav collapse
.sidenav-toggled {
    #layoutSidenav {
        #layoutSidenav_nav {
            transform: translateX(0);
        }

        #layoutSidenav_content {
            &:before {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: $black;
                z-index: $zindex-content;
                opacity: 0.5;
                transition: opacity 0.3s ease-in-out;
            }
        }
    }
}

// Responsive styling for the sidenav layout
@include media-breakpoint-up(lg) {
    #layoutSidenav {
        #layoutSidenav_nav {
            transform: translateX(0);
        }

        #layoutSidenav_content {
            margin-left: 0;
            transition: margin 0.15s ease-in-out;
        }
    }

    // Behavior for the sidenav collapse on screens larger than the med breakpoint
    .sidenav-toggled {
        #layoutSidenav {
            #layoutSidenav_nav {
                transform: translateX(-$sidenav-base-width);
            }

            #layoutSidenav_content {
                margin-left: -$sidenav-base-width;

                // Removes the sidenav overlay on screens larger than the med breakpoint
                &:before {
                    display: none;
                }
            }
        }
    }
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
    color: #333;
    background-color:#95C4F7; /* #7ab800;  */
    border-bottom: 1px dashed;
}

.dropdownFilter .ng-dropdown-panel .ng-dropdown-panel-items{
    max-height: none !important;
}
.dropdownFilter .ng-dropdown-panel .scroll-host{
    overflow: visible !important;
}
.dropdownFilter .ng-select.ng-select-multiple .ng-select-container .ng-value-container{
    flex-wrap: nowrap !important;
    overflow: auto;
}
.dropdownFilter .dropdown-menu {
    max-width: 30rem;
}
.date-range-wrap .input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu), .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3){
    border-top-left-radius: 15%;
    border-bottom-left-radius: 15%;
}
.date-range-wrap .input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback){
    border-top-right-radius: 5%;
    border-bottom-right-radius: 5%;
}
/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 25px;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 25px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

  .cdk-overlay-container, .cdk-overlay-connected-position-bounding-box, .cdk-overlay-pane, .owl-dt-container {
    z-index: 1099;

  }
  .offcanvas-end {
    width: 40%;
  }
  .input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu), .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3) {
    border-top-right-radius: 0.35rem;
    border-bottom-right-radius: 0.35rem;
    border-top-left-radius: 0.35rem;
    border-bottom-left-radius: 0.35rem;
}
