// * * * * * * * * * * * * * *
// * * Import core styles  * *
// * * * * * * * * * * * * * *

// Includes Bootstrap and styling shared across
// all themes using the Start Bootstrap Pro
// design language

@import "./core/styles";

// * * * * * * * * * * * * * * * * * *
// * * Import SB Admin Pro styles  * *
// * * * * * * * * * * * * * * * * * *

// Includes styling specific to the SB Admin Pro
// theme - must load after core styles

@import "./admin/styles";

// * * * * * * * * * * * * * * * * * * * *
// * * Import Angular specific styles  * *
// * * * * * * * * * * * * * * * * * * * *

// Styling specific to your project
// The file imported below is intentionally blank and should
// be used to create styling specific to your project

@import "./sb-admin-pro-angular";

// * * * * * * * * * * * * * * *
// * * Import project styles * *
// * * * * * * * * * * * * * * *

// Styling specific to your project
// The below file is intentionally blank and should
// be used to create styling specific to your project

@import "./project";

@import "~@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";

@import "highcharts.css";

.side-nav-bg-c {
  background-color: $sidenav-light-bg !important;
}

.side-nav-search-bar {
  .input-group-text {
    padding: 0.875rem 0.125rem 0.875rem 0.8rem;
    border-radius: 1.5rem;
  }

  input {
    padding: 0.875rem 1.125rem 0.875rem 0.725rem;
    border-radius: 1.5rem;
  }

  .input-group-joined {
    border-radius: 1.5rem;
  }

  .input-group-joined:focus-within {
    box-shadow: none !important;
  }
}

.icon {
  width: 1rem;
  height: 1rem;
}

.date-range-wrap {
  display: inline-block;

  button {
    background-color: #fff;
    border: none !important;
    box-shadow: none !important;
    outline: none !important;

    &:hover,
    &:focus {
      background-color: transparent;
      border: none;
      box-shadow: none;
      outline: none;
    }
  }
}

.ng-select .ng-select-container {
  border: none !important;
}

.rounded-2x {
  border-radius: 2rem !important;
}

.btn-outline-dotted {
  color: $gray-900 !important;
  border: 1px dashed $gray-900 !important;

  &:hover,
  &:focus {
    border: none;
    box-shadow: none;
    outline: none;
  }
}

.smart-water-meter-card {
  height: 26rem;

  .card-top {
    background: rgb(0 97 242 / 40%);

    .drop-img-wrap {
      padding-left: 1.5rem;

      .drop-img {
        width: 2rem;
        height: 2.5rem;
        object-fit: contain;
      }
    }

    .content {
      padding-right: 1.5rem;

      small {
        color: $primary;
        font-size: 0.9rem;
      }

      p {
        color: $primary;
        font-size: 1.1rem;
        font-weight: 600;
      }

      span {
        color: #007e00;
        vertical-align: middle;

        img {
          width: 1.4rem;
        }
      }
    }
  }

  .card-body {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 0.5rem;

    .shift-item {
      h5 {
        font-weight: 600;
        font-size: 0.9rem;
        margin-top: 0.3rem;
        margin-bottom: 0.4rem;
      }

      p {
        font-size: 0.8rem;
        color: $black;

        span {
          color: $gray-400;
        }
      }
    }

    .icon-box {
      background: rgb(0 97 242 / 10%);
      width: 3rem;
      height: 3rem;
      border-radius: 50%;

      img {
        width: 1rem;
        height: 1rem;
        opacity: 1;
      }
    }
  }
}

.notifications-card {
  height: 26rem;

  .card-header {
    .icon-box {
      background: rgb(0 97 242 / 10%);
      width: 3rem;
      height: 3rem;
      border-radius: 50%;

      img {
        width: 1rem;
        height: 1rem;
        opacity: 1;
      }
    }
  }

  .card-body {
    .timeline {
      height: 19rem;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-thumb {
        background: $gray-400;
        border-radius: 20px;
      }

      &::-webkit-scrollbar-track {
        background: $gray-200;
        border-radius: 20px;
      }
    }
  }
}

.estimate-card {
  height: 26rem;

  .divider {
    border-radius: 1rem;
    height: 0.7rem;
  }

  .items-list {
    height: 14.4rem;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background: $gray-400;
      border-radius: 20px;
    }

    &::-webkit-scrollbar-track {
      background: $gray-200;
      border-radius: 20px;
    }

    .item {
      gap: 2%;
      margin-bottom: 0.8rem;

      &:last-child {
        margin-bottom: 0;
      }

      p {
        font-weight: 600;
        color: $black;
      }

      .left {
        width: 45%;
      }

      .right {
        width: 45%;
      }

      .center {
        width: 8%;

        .drop-img-sm {
          width: 1rem;
        }
      }
    }
  }
}

.bullet-item {
  .bullet {
    width: 0.7rem;
    height: 0.7rem;
    border-radius: 50%;
  }
}

.vertical-bar-chart-card {
  .icons-right {
    svg {
      cursor: pointer;
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}

.sidenav .sidenav-menu .nav .nav-link .nav-link-icon {
  padding-right: 0.1rem;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  padding: 4px !important;
  font-size: smaller !important;
}

.custom-ng-select .ng-select-container {
  max-height: 200px !important;
  overflow: auto !important;
  height: 100% !important;
  align-items: flex-start !important;
}

.custom-ng-select .ng-select-container.ng-has-value {
  min-height: 100px !important;
}

.picsum-img-wrapper img{
  height: 100%;
  width: 100%;
  object-fit: cover;
}

