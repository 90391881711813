// Styling used on the Style Reference component and utility demo pages

// Restyle dropdowns to demo them as visible, inline elements
.sbp-dropdown-example {
    .dropdown-menu {
        display: inline-block;
        position: relative;
        float: none;
        max-width: 15rem;
    }
}

// Preview windows for style reference - components and utilities
.sbp-preview {
    border-radius: $border-radius;
    border: $border-width-lg solid $border-color;
    border-style: solid;

    .sbp-preview-content {
        padding: $grid-gutter-width;
        border-bottom: $border-width solid $border-color;
        position: relative;
    }

    .sbp-preview-code {
        background-color: $gray-100;
        padding: $grid-gutter-width;
        border-bottom: $border-width solid $border-color;

        .nav-tabs {
            border-bottom-color: transparent;
            font-size: 0.75rem;
            font-weight: 500;

            .nav-item .nav-link {
                border-bottom: 0;
                display: inline-flex;
                align-items: center;

                &.active {
                    border-color: transparent;
                    background-color: #2f2f2f;
                    color: white;
                }

                .img-pug {
                    height: 1em;
                    padding: 1px 0;
                }
            }
        }
    }

    .sbp-preview-text {
        background-color: $gray-100;
        font-size: $font-size-sm;
        padding: $grid-gutter-width;
    }
}

// Prism Theme

code[class*="language-"],
pre[class*="language-"] {
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;
    color: #eee;
    background: #2f2f2f;
    font-family: 'SFMono-Regular', 'Consolas', 'Liberation Mono', 'Menlo', monospace;
    font-size: 0.875rem;
    line-height: 1.5em;

    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;

    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
}

code[class*="language-"]::-moz-selection,
pre[class*="language-"]::-moz-selection,
code[class*="language-"] ::-moz-selection,
pre[class*="language-"] ::-moz-selection {
    background: #363636;
}

code[class*="language-"]::selection,
pre[class*="language-"]::selection,
code[class*="language-"] ::selection,
pre[class*="language-"] ::selection {
    background: #363636;
}

:not(pre)>code[class*="language-"] {
    white-space: normal;
    border-radius: 0.2em;
    padding: 0.1em;
}

pre[class*="language-"] {
    overflow: auto;
    position: relative;
    margin: 0;
    padding: 1rem;
    max-height: 35rem;
}

.language-css>code,
.language-sass>code,
.language-scss>code {
    color: #fd9170;
}

[class*="language-"] .namespace {
    opacity: 0.7;
}

.token.atrule {
    color: #c792ea;
}

.token.attr-name {
    color: #ffcb6b;
}

.token.attr-value {
    color: #a5e844;
}

.token.attribute {
    color: #a5e844;
}

.token.boolean {
    color: #c792ea;
}

.token.builtin {
    color: #ffcb6b;
}

.token.cdata {
    color: #80cbc4;
}

.token.char {
    color: #80cbc4;
}

.token.class {
    color: #ffcb6b;
}

.token.class-name {
    color: #f2ff00;
}

.token.comment {
    color: #616161;
}

.token.constant {
    color: #c792ea;
}

.token.deleted {
    color: #ff6666;
}

.token.doctype {
    color: #616161;
}

.token.entity {
    color: #ff6666;
}

.token.function {
    color: #c792ea;
}

.token.hexcode {
    color: #f2ff00;
}

.token.id {
    color: #c792ea;
    font-weight: bold;
}

.token.important {
    color: #c792ea;
    font-weight: bold;
}

.token.inserted {
    color: #80cbc4;
}

.token.keyword {
    color: #c792ea;
}

.token.number {
    color: #fd9170;
}

.token.operator {
    color: #89ddff;
}

.token.prolog {
    color: #616161;
}

.token.property {
    color: #80cbc4;
}

.token.pseudo-class {
    color: #a5e844;
}

.token.pseudo-element {
    color: #a5e844;
}

.token.punctuation {
    color: #89ddff;
}

.token.regex {
    color: #f2ff00;
}

.token.selector {
    color: #ff6666;
}

.token.string {
    color: #a5e844;
}

.token.symbol {
    color: #c792ea;
}

.token.tag {
    color: #ff6666;
}

.token.unit {
    color: #fd9170;
}

.token.url {
    color: #ff6666;
}

.token.variable {
    color: #ff6666;
}
