// Global resets

html,
body {
    height: 100%;
}

body {
    overflow-x: hidden;
}

// Bring in custom font for global use
@font-face {
    font-family: 'Metropolis';
    src: url('/assets/fonts/metropolis/Metropolis-Thin.otf');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis';
    src: url('/assets/fonts/metropolis/Metropolis-ThinItalic.otf');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Metropolis';
    src: url('/assets/fonts/metropolis/Metropolis-ExtraLight.otf');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis';
    src: url('/assets/fonts/metropolis/Metropolis-ExtraLightItalic.otf');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Metropolis';
    src: url('/assets/fonts/metropolis/Metropolis-Light.otf');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis';
    src: url('/assets/fonts/metropolis/Metropolis-LightItalic.otf');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Metropolis';
    src: url('/assets/fonts/metropolis/Metropolis-Regular.otf');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis';
    src: url('/assets/fonts/metropolis/Metropolis-RegularItalic.otf');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Metropolis';
    src: url('/assets/fonts/metropolis/Metropolis-Medium.otf');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis';
    src: url('/assets/fonts/metropolis/Metropolis-MediumItalic.otf');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Metropolis';
    src: url('/assets/fonts/metropolis/Metropolis-SemiBold.otf');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis';
    src: url('/assets/fonts/metropolis/Metropolis-SemiBoldItalic.otf');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Metropolis';
    src: url('/assets/fonts/metropolis/Metropolis-Bold.otf');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis';
    src: url('/assets/fonts/metropolis/Metropolis-BoldItalic.otf');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Metropolis';
    src: url('/assets/fonts/metropolis/Metropolis-ExtraBold.otf');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis';
    src: url('/assets/fonts/metropolis/Metropolis-ExtraBoldItalic.otf');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Metropolis';
    src: url('/assets/fonts/metropolis/Metropolis-Black.otf');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis';
    src: url('/assets/fonts/metropolis/Metropolis-BlackItalic.otf');
    font-weight: 800;
    font-style: italic;
}


#overlay {
    position: absolute;
    display: none;
    width: 100%;
    height: 135vh;
    left: 0;
    right: 0;
    background-color: rgba(200,200,200,0.5);
    z-index: 5;
  }
  #text{
    position: relative;
    top: 50%;
    left: 60%;
    font-size: 50px;
    transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    width: 50%;
    margin: auto;
    cursor: pointer;
  }

  .highcharts-background, .highcharts-button-box{
    fill: none !important;
  }
  .highcharts-column-series rect.highcharts-point, .highcharts-grid-line, .highcharts-pie-series .highcharts-point{
    stroke: #e4e4e4 !important;
  }
  
  .highcharts-tooltip-box {
    stroke-width: 1px;
    fill: #fff !important;
    color: #000 !important;
}
.highcharts-tooltip text {
    fill:  #000 !important;
    font-size: 0.8em;
}
.highcharts-background{
    background-color: #fff;
}

// .meterComparison .ng-dropdown-panel{
//     margin-top: -3rem !important;
// }
.meterComparison {
    height: 3vh;
}
.meterComparison.ng-select.ng-select-opened.ng-select-bottom>.ng-select-container {
    height: inherit!important;
}
.meterComparison.ng-select .ng-select-container {
    height: 2.5rem!important;
}

.multiSelect {
  height: 8vh;
}
.multiSelect.ng-select .ng-select-container {
  height: 7.5rem!important;
  overflow: scroll;
}

@media (min-width: 1200px){
    .modal-xl {
        max-width: 1440px;
    }
  }

  form .row > * {
    // padding-right: .2rem;
    // padding-left: .2rem;
  }
  #credential_picker_container{
    display: none !important;
  }
  .highcharts-plot-band{
    fill: #e81500 !important;
    fill-opacity: 1;
  }
  .highcharts-heatmap-series .highcharts-point{
    fill: revert-layer;
    stroke: revert-layer;
  }
  
  .drilldown .highcharts-color-0, .wastageAnalysis .highcharts-color-1, .wastageAnalysis .highcharts-color-0{
    fill: revert-layer;
    stroke: revert-layer;
  }
  .homeDash .highcharts-color-0{
    fill: revert-layer;
    stroke: revert-layer;
  }
  .chartsBilling .highcharts-color-0, .chartsBilling .highcharts-color-1, .chartsBilling .highcharts-color-2, .chartsBilling .highcharts-color-3{
    fill: revert-layer;
    stroke: revert-layer;
  }
  .topTen .highcharts-color-0, .balancing .highcharts-color-0{
    fill: revert-layer;
    stroke: revert-layer;
  }
  .custom .highcharts-color-0, .custom .highcharts-color-1, .custom .highcharts-color-2 , 
    .custom .highcharts-color-3, .custom .highcharts-color-4, .custom .highcharts-color-5, 
    .custom .highcharts-color-6, .custom .highcharts-color-7, .custom .highcharts-color-8{
    fill: revert-layer;
    stroke: revert-layer;
  }
  .monthlyComparison .highcharts-color-0{
    fill: revert-layer;
    stroke: revert-layer;
  }


  .touChart .highcharts-color-0, .touChart .highcharts-color-1, .touChart .highcharts-color-2, 
  .touChart .highcharts-color-3, .touChart .highcharts-color-4, .touChart .highcharts-color-5{
    fill: revert-layer;
    stroke: revert-layer;
  }

  .chart-line .highcharts-color-0, .chart-line .highcharts-color-1, .chart-line .highcharts-color-2, .chart-line .highcharts-color-3
  .chart-line .highcharts-color-4, .chart-line .highcharts-color-5 {
    fill: revert-layer;
    stroke: revert-layer;
  }
  .tracker .highcharts-color-0, .tracker .highcharts-color-1, .tracker .highcharts-color-2, .tracker .highcharts-color-3
  .tracker .highcharts-color-4, .tracker .highcharts-color-5 {
    fill: revert-layer;
    stroke: revert-layer;
  }

  .wastage {
    display: block;
    width: 100%;
    padding: 0.875rem 1.125rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1;
    color: #69707a;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #c5ccd6;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.35rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    height: 8vh;
  }

  .wastage.ng-select .ng-select-container{
    height: 3vh !important;
  }
  .highcharts-plot-band {
    fill: revert-layer !important;
  }
  .highcharts-gauge-series .highcharts-dial{
    fill: revert-layer !important;
  }
  .highcharts-yaxis .highcharts-tick {
    stroke-width: 4px;
    stroke: #fff;
}
.pac-container {
  padding-bottom: 12px;
  margin-right: 12px;
  z-index: 99999;
}

.mapSections .ng-dropdown-panel.ng-select-bottom {
  margin-top: -95px;
}
.feather-map {
  width: 1.3rem !important;
  height: 1.3rem !important;
}
.elip{
  white-space: nowrap; 
  max-width: 200px; 
  overflow: hidden;
  text-overflow: ellipsis; 
}
.custom-background-task-canvas {
  height: 8.0rem !important;
  top: 4rem;
  overflow: hidden;
  padding: 0;
}
.externalReport{
  left: -7rem !important;
}

.progress-background-task-canvas {
  height: 8.5rem !important;
  top: 4rem;
  overflow: hidden;
  padding: 0;
}
.offcanvas-end{
  border: none;
}
.billing .accordion-button{
  padding: 0;
}