.nav-fixed {
    .topnav {
        @extend .fixed-top;
        z-index: $zindex-topnav;
    }

    #layoutSidenav {
        #layoutSidenav_nav_col {
            position: relative;
            z-index: $zindex-sidenav;
            padding-top: calc($topnav-base-height + 2rem);
            padding-right: 0.5rem;

            .logo {
                width: calc(100% + 0.5rem);
            }

            .icon-box {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 3rem;
                height: 3rem;
                cursor: pointer;
                margin-inline: auto;
                
                &:hover, &.active-item {
                    background-color: #ffffff42;
                    border-top-right-radius: 50%;
                    border-bottom-right-radius: 50%;
                    box-shadow: inset 4px 0px 0px 0px #ffffff;
                }
            }

            .bottom-menu {
                position: absolute;
                bottom: 10rem;
                left: 0;
                width: 3rem;
            }
        }
        #layoutSidenav_nav {
            @extend .fixed-top;
            width: $sidenav-base-width;
            height: 100vh;
            z-index: $zindex-sidenav;
            max-width: 24%;

            .sidenav {
                padding-top: $topnav-base-height;
                max-width: 95%;

                .sidenav-menu {
                    @include scrollbar-custom;
                }

                // Scrollbar coloring for dark sidebar color option
                &.sidenav-dark {
                    .sidenav-menu {
                        &::-webkit-scrollbar-thumb {
                            background-color: fade-out($white, 0.75);
                            border-color: rgba(0, 0, 0, 0);
                        }
                    }
                }
            }
        }

        #layoutSidenav_content {
            padding-left: $sidenav-base-width;
            top: $topnav-base-height;
        }
    }
}

.modal-footer{
    justify-content: space-between;
}
