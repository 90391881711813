// Override Bootstrap component variables
//
// define/redefine common padding and border radius sizes and more.

$border-width: 1px;
$border-color: $gray-200;

$border-radius: 0.35rem;
$border-radius-lg: 0.5rem;
$border-radius-sm: 0.25rem;

// Theme variable for custom border radius size

$border-radius-xl: 1rem;

// Theme variables to set custom border variations

$border-width-lg: 0.25rem;

$box-shadow-sm: 0 0.125rem 0.25rem 0 rgba($gray-900, 0.2);
$box-shadow: 0 0.15rem 1.75rem 0 rgba($gray-900, 0.15);
$box-shadow-lg: 0 1rem 3rem rgba($black, 0.175);

$transition-collapse: height 0.15s ease;

// Theme variables for extended box shadow variations

$box-shadow-right-sm: 0.125rem 0 0.25rem 0 rgba($gray-900, 0.15);
$box-shadow-right: 0.15rem 0 1.75rem 0 rgba($gray-900, 0.15);
$box-shadow-right-lg: 1rem 0 3rem 0 rgba($gray-900, 0.15);

$box-shadow-left-sm: -0.125rem 0 0.25rem 0 rgba($gray-900, 0.15);
$box-shadow-left: -0.15rem 0 1.75rem 0 rgba($gray-900, 0.15);
$box-shadow-left-lg: -1rem 0 3rem 0 rgba($gray-900, 0.15);

// Theme variables used to create the lift utility

$box-shadow-lift-sm: 0 0.25rem 1rem 0 rgba($gray-900, 0.25);
$box-shadow-lift: 0 0.5rem 2rem 0 rgba($gray-900, 0.25);
$box-shadow-lift-lg: 0 1rem 2rem 0 rgba($gray-900, 0.25);
